.assesmenttool {
  
  .ant-modal-header {
    background: #E6FBFE !important;
  }

  .ant-modal-title {
    color: #000 !important;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .input-class {
    border: 1px solid #d9d9d9;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    height: 40px;
    width: 100%;
    padding: 5px;
    background-color: #fff;
  }

  .bg {
    &-categorie {
      background-color: #111e62;
      color: #fff;
      font-size: 24px;
      padding: 10px;
    }
    &-competencie,
    &-title-inspect {
      background-color: #e3e3f4;
      height: auto;
    }
    &-title-inspect {
      background-color: #babadc;
    }
  }

  .card,
  .selected-card {
    width: 24%;
    height: 250px;
    color: black;
    @media (max-width: 768px) {
      height: auto;
      width: 49%;
    }
  }
  .selected-card {
    background-color: #4da4da;
  }
  .inspect-odd {
    background-color: #e3e3f4;
  }
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 16px 24px;
    height: 58px;
    font-size: 18px;
    color: black;
    background-color: #F0F3F4;
  }

  .ant-collapse-header-text {
    color: black;
    font-weight: 600;
  }

  .ant-select-selector {
    height: 48px !important;
    display: flex;
    align-items: center;
  }

  .ant-select {
    height: 48px;
  }
}
